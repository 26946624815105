'use client'

import useTranslation from 'app/i18n/client'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import { useRouter, useSearchParams } from 'next/navigation'
import useGlobalContext from 'app/components/GlobalContext/useGlobalContext'
import { addDays } from 'date-fns'
import { useCallback, useEffect } from 'react'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import styles from './Filter.module.scss'
import { IComposeComponentProps } from '../../types'

const LOCAL_STORAGE_KEY = 'dynamicPagePreferences'

// We can pull this function out into a shared utility if we need it elsewhere
const getLocalStorageData = () => {
  const dynamicPagePreferences = localStorage.getItem(LOCAL_STORAGE_KEY)
  const dynamicPagePreferencesData = dynamicPagePreferences
    ? JSON.parse(dynamicPagePreferences)
    : undefined

  if (dynamicPagePreferencesData && dynamicPagePreferencesData.lastSaved) {
    const expirationDate = addDays(
      dynamicPagePreferencesData.lastSaved,
      14
    ).getTime()

    if (Date.now() > expirationDate) {
      localStorage.removeItem(LOCAL_STORAGE_KEY)
    } else {
      return dynamicPagePreferencesData
    }
  }

  return {
    filter: '',
  }
}

interface FilterProps
  extends Pick<
    IComposeComponentProps,
    'id' | 'parentComponentId' | 'positionInParentComponent'
  > {}

function Filter({
  id,
  parentComponentId,
  positionInParentComponent,
}: FilterProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const { slug, locale } = useGlobalContext()
  const params = useSearchParams()
  const contentFilter = params?.get('filter') || 'allAges'

  const onFilterChange = useCallback(
    (filter) => {
      const searchParams = new URLSearchParams(window.location.search)
      if (filter) searchParams.set('filter', filter)
      else searchParams.delete('filter')

      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
          lastSaved: Date.now(),
          filter,
        })
      )

      router.replace(
        // @ts-ignore - The types do not match because we are using experimental typedRoutes
        `/${locale}/${slug}?${searchParams.toString()}`,
        { scroll: false }
      )
    },
    [slug, router, locale]
  )

  useEffect(() => {
    if (contentFilter) return
    const localStorageData = getLocalStorageData()
    if (localStorageData.filter) onFilterChange(localStorageData.filter)
  }, [contentFilter, onFilterChange])

  return (
    <TrackImpression
      as='div'
      unitName={id}
      unitLocation='inline'
      buttonName='filter'
      className='no-print'
      componentId={id}
      parentComponentId={parentComponentId}
      positionInParentComponent={positionInParentComponent}
    >
      <select
        data-testid='filter-select'
        className={`${styles.select}
          border-foreground-grey4 rounded-sm-1 border-shadow bg-background-high-white text-foreground-darkblue
        `}
        value={contentFilter}
        onChange={(e) => {
          onFilterChange(e.target.value)
          trackEngagement({
            eventType: 'select',
            unitName: id,
            componentId: id,
            unitLocation: 'inline',
            submittedObject: { filter: e.target.value },
            parentComponentId,
            positionInParentComponent,
          })
        }}
      >
        <option value='' disabled hidden>
          {t('filter-by-ages')}
        </option>
        <option value='all_ages'>{t('for-everyone')}</option>
        <option value='kids'>{t('for-parents-of-kids')}</option>
        <option value='teens'>{t('for-parents-of-teens')}</option>
      </select>
    </TrackImpression>
  )
}

export default Filter
