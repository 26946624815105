'use client'

import { DownloadableAssetFragment } from '@generated/schema'
import { IComposeComponentProps } from 'app/components/Compose/types'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import SvgEmail from 'app/components/Svg/SvgEmail'
import SvgLoading from 'app/components/Svg/SvgLoading'
import SvgPrinter from 'app/components/Svg/SvgPrinter'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import { getContentUrl } from 'components/shared/utils/getContentUrl'
import Image from 'next/image'
import { useRef, useState } from 'react'
import styles from './DownloadableAssetContent.module.scss'

const UNIT_VARIATION = 'downloadable_asset_content'
const FALLBACK_IMG =
  'https://images.ctfassets.net/p0qf7j048i0q/hJEgpmjWW4RhB03AdHmpq/8e08ba4df94c7f59bb2fa91104ca9815/empty_article.svg'

interface IDownloadableAssetContentProps
  extends Pick<
    IComposeComponentProps,
    'id' | 'parentComponentId' | 'positionInParentComponent'
  > {
  index: number
  downloadableAsset: DownloadableAssetFragment
}

async function getObjectUrlFromDownloadUrl(url: string) {
  const response = await fetch(url)
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

function EmailBtn({
  positionInParentComponent,
  parentComponentId,
  id,
  index,
  downloadableAsset,
}: IDownloadableAssetContentProps) {
  const url = getContentUrl(downloadableAsset)
  return (
    <TrackClick
      as='a'
      href={`mailto:?subject=${encodeURIComponent(
        downloadableAsset.title || ''
      )}&body=${encodeURIComponent(url)}`}
      aria-label='email'
      data-testid='downloadable-asset-email-link'
      isEngagementEvent
      trackProps={{
        eventType: 'share',
        platform: 'email',
        unitName: id,
        componentId: downloadableAsset.sys.id,
        unitLocation: 'inline',
        positionInUnit: index + 1,
        unitVariation: UNIT_VARIATION,
        parentComponentId,
        positionInParentComponent,
        linkedContentId: downloadableAsset.sys.id,
        linkedContentUrl: url,
      }}
    >
      <SvgEmail className='w-sm-4 mr-sm-3' />
    </TrackClick>
  )
}

function PrintBtn({
  positionInParentComponent,
  parentComponentId,
  id,
  index,
  downloadableAsset,
}: IDownloadableAssetContentProps) {
  const url = getContentUrl(downloadableAsset)
  const [loading, setLoading] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>()
  const [urlBlob, setUrlBlob] = useState<string>()

  const handlePrint = async () => {
    if (loading) return

    trackEngagement({
      eventType: 'share',
      platform: 'print',
      unitName: id,
      componentId: downloadableAsset.sys.id,
      unitLocation: 'inline',
      positionInUnit: index + 1,
      unitVariation: UNIT_VARIATION,
      parentComponentId,
      positionInParentComponent,
      linkedContentId: downloadableAsset.sys.id,
      linkedContentUrl: url,
    })

    if (iframeRef.current) {
      iframeRef.current.contentWindow?.print()
      return
    }

    setLoading(true)
    const objectUrl = await getObjectUrlFromDownloadUrl(url)
    setUrlBlob(objectUrl)
  }

  return (
    <button
      type='button'
      disabled={loading}
      className='Btn-link'
      onClick={handlePrint}
      aria-label='print'
      data-testid='downloadable-asset-print-btn'
    >
      {loading ? (
        <SvgLoading className='w-sm-4 fill-foreground-darkblue' />
      ) : (
        <SvgPrinter className='w-sm-4' />
      )}

      {/* using the iframe was the only way I could trigger window.print()
       if any new option is available, please replace it */}
      {urlBlob && (
        <iframe
          className='d-sm-none'
          ref={iframeRef as any}
          title='print'
          src={urlBlob}
          data-testid='downloadable-asset-print-iframe'
          onLoad={() => {
            if (!iframeRef.current) return
            iframeRef.current.contentWindow?.print()
            setLoading(false)
          }}
        />
      )}
    </button>
  )
}

export default function DownloadableAssetContent({
  id,
  parentComponentId,
  positionInParentComponent,
  downloadableAsset,
  index,
}: IDownloadableAssetContentProps) {
  const contentUrl = getContentUrl(downloadableAsset)

  return (
    <div
      data-testid={`downloadable-asset-content-${index}`}
      className={`px-sm-4 py-sm-5 p-md-0 w-sm-full bg-background-low-white rounded-sm-1 border-shadow 
        d-sm-flex flex-md-column-reverse`}
    >
      <TrackImpression
        as='span'
        key={downloadableAsset.sys.id}
        linkedContentId={downloadableAsset.sys.id}
        linkedContentUrl={contentUrl}
        unitName={id}
        unitLocation='inline'
        componentId={downloadableAsset.sys.id}
        positionInUnit={index + 1}
        unitVariation={UNIT_VARIATION}
        isContent
        parentComponentId={parentComponentId}
        positionInParentComponent={positionInParentComponent}
      />

      <div className='p-md-4 flex-sm d-sm-flex flex-sm-column flex-sm-justify-between mr-sm-4 mr-md-0'>
        <div className='lc-sm-3 p-sm-relative mb-sm-4 mb-md-5'>
          <bdi>
            <TrackClick
              href={contentUrl}
              className='primary-link no-underline lh-condensed f-sm-4 f-md-5 stretched-link d-sm-block'
              data-testid='downloadable-asset-content-link'
              isEngagementEvent={false}
              trackProps={{
                clickType: 'text',
                linkText: downloadableAsset.title,
                isContent: true,
                buttonName: null,
                linkedContentId: downloadableAsset.sys.id,
                linkedContentUrl: contentUrl,
                unitName: id,
                componentId: downloadableAsset.sys.id,
                unitLocation: 'inline',
                positionInUnit: index + 1,
                unitVariation: UNIT_VARIATION,
                parentComponentId,
                positionInParentComponent,
              }}
            >
              {downloadableAsset.title}
            </TrackClick>
          </bdi>
        </div>

        <ul className='list-reset d-sm-flex'>
          <li className='mr-sm-5'>
            <PrintBtn
              id={id}
              index={index}
              parentComponentId={parentComponentId}
              positionInParentComponent={positionInParentComponent}
              downloadableAsset={downloadableAsset}
            />
          </li>

          <li>
            <EmailBtn
              id={id}
              index={index}
              parentComponentId={parentComponentId}
              positionInParentComponent={positionInParentComponent}
              downloadableAsset={downloadableAsset}
            />
          </li>
        </ul>
      </div>

      <div
        className={`${styles.imageWrapper} p-sm-relative rounded-sm-1 o-sm-hidden no-print`}
      >
        <Image
          sizes='(max-width: 767px) 120px, 300px'
          className='of-sm-cover'
          fill
          src={downloadableAsset.thumbnail?.url || FALLBACK_IMG}
          alt=''
        />
      </div>
    </div>
  )
}
