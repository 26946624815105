/* eslint max-len: 0 */
/* eslint react/no-unknown-property: 0 */

'use client'

import trackClick from 'app/components/Snowplow/trackClick'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import { IComposeComponentProps } from '../../types'

interface IDonateIframeProps
  extends Pick<
    IComposeComponentProps,
    'id' | 'parentComponentId' | 'positionInParentComponent'
  > {
  pageLink: string
}

export default function DonateIframe({
  pageLink,
  id,
  parentComponentId,
  positionInParentComponent,
}: IDonateIframeProps) {
  const [isThankYouPage, setIsThankYouPage] = useState(false)

  useEffect(() => {
    window.iFrameResize?.({ checkOrigin: false })

    let count = 0

    const intervalId = setInterval(() => {
      window.iFrameResize?.({ checkOrigin: false })
      count += 1

      if (count > 10) {
        clearInterval(intervalId)
      }
    }, 500)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    function receiveMessage(event) {
      if (event.origin !== 'https://raisedonors.com') return

      if (
        event.data.eventType === 'impression' &&
        event.data.unitName === 'donation_thank_you' &&
        !isThankYouPage
      ) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setIsThankYouPage(true)
      }

      if (event.data.type === 'navigation') {
        trackClick({
          clickType: event.data.eventType,
          buttonName: event.data.buttonName,
          unitName: event.data.unitName,
          unitLocation: 'inline',
          eventHorizontalPosition: window.scrollX,
          eventVerticalPosition: window.scrollY,
          componentId: id,
          parentComponentId,
          positionInParentComponent,
        })
      } else if (event.data.type === 'engagement') {
        trackEngagement({
          eventType: event.data.eventType,
          unitName: event.data.unitName,
          unitLocation: 'inline',
          submittedObject: event.data.submittedObject,
          componentId: id,
          parentComponentId,
          positionInParentComponent,
        })
      }
    }

    window.addEventListener('message', receiveMessage, false)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [setIsThankYouPage, isThankYouPage])

  return (
    <>
      <iframe
        id={`raiseDonorsEmbedForm_${id}`}
        style={{ height: '2000px' }}
        allow='payment'
        title='Donation form'
        src={`https://raisedonors.com/understood/${pageLink}?embed=true`}
        width='100%'
        frameBorder='0'
        scrolling='no'
        // @ts-ignore
        allowpaymentrequest='true'
        allowtransparency='true'
        onLoad={() => {
          window.iFrameResize?.({ checkOrigin: false })
        }}
      />

      <Script
        type='text/javascript'
        src='https://raisedonors.com/js/donation/iframeSizer-referenced-by-iframe.min.js'
      />

      <Script type='text/javascript' id='donate-script'>
        {`try{ var parent_query = window.location.search; console.log("parent query: " + parent_query); if(parent_query.length) { var res = parent_query.charAt(0); console.log("first char: " + res); if(res == "?"){ parent_query = parent_query.replace("?", "&"); console.log("replacing ? with &: " + parent_query); } var newEmbedUrl = document.getElementById("raiseDonorsEmbedForm_${id}").getAttribute("src") + parent_query; console.log("new embed url: " + newEmbedUrl); document.getElementById("raiseDonorsEmbedForm_${id}").setAttribute("src", newEmbedUrl); } }catch(err){ console.log(err); }`}
      </Script>
    </>
  )
}
