'use client'

import { DownloadableAssetFragment } from '@generated/schema'
import trackClick from 'app/components/Snowplow/trackClick'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import SvgDownload from 'app/components/Svg/SvgDownload'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import useTranslation from 'app/i18n/client'
import { useState } from 'react'
import { IComposeComponentProps } from '../../types'
import SubscribeModal from './SubscribeModal/SubscribeModal.client'

interface IDownloadItemProps
  extends Pick<
    IComposeComponentProps,
    'id' | 'parentComponentId' | 'positionInParentComponent'
  > {
  content: DownloadableAssetFragment
  emailTags: string[]
  index: number
}

const localStorageKey = 'TeaserListEmailProvided'

export default function DownloadItem({
  id,
  parentComponentId,
  positionInParentComponent,
  content,
  emailTags,
  index,
}: IDownloadItemProps) {
  const { t } = useTranslation()
  const [isModalOpened, setIsModalOpened] = useState(false)

  const handleDownload = () => {
    if (!content.asset?.url) return

    trackEngagement({
      eventType: 'download',
      unitName: id,
      componentId: content.sys.id,
      unitLocation: 'inline',
      linkedContentId: content.sys.id,
      linkedContentUrl: content.asset.url,
      unitVariation: 'download_content',
      positionInUnit: index + 1,
      parentComponentId,
      positionInParentComponent,
    })

    fetch(content.asset.url).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = content.title || ''
        a.click()
      })
    })
  }

  const handleClick = () => {
    trackClick({
      clickType: 'button',
      isContent: true,
      buttonName: 'download',
      buttonText: t('download'),
      linkedContentId: content.sys.id,
      linkedContentUrl: content.asset?.url || '',
      unitName: id,
      componentId: id,
      unitLocation: 'inline',
      positionInUnit: index + 1,
      unitVariation: 'download_content',
      parentComponentId,
      positionInParentComponent,
    })

    if (localStorage.getItem(localStorageKey)) {
      handleDownload()
    } else {
      setIsModalOpened(true)
    }
  }

  return (
    <>
      <TrackImpression
        as='div'
        key={content.sys.id}
        linkedContentUrl={content.asset?.url || ''}
        unitName={id}
        unitLocation='inline'
        componentId={content.sys.id}
        positionInUnit={index + 1}
        unitVariation='download_content'
        buttonName='download'
        buttonText={t('download')}
        linkedContentId={content.sys.id}
        isContent
        parentComponentId={parentComponentId}
        positionInParentComponent={positionInParentComponent}
      />
      <p className='f-sm-3 f-lg-4 lh-condensed text-bold flex-sm mr-sm-3'>
        {content.title}
        <span className='d-sm-block mt-sm-2 f-sm-1 text-foreground-slate text-regular'>
          {t('PDF')}
        </span>
      </p>
      <div>
        <button
          type='button'
          className='stretched-link Btn Btn--primary Btn--small no-underline f-sm-2'
          onClick={handleClick}
        >
          <SvgDownload className='w-sm-3 mr-sm-2' />
          {t('download')}
        </button>
      </div>
      <SubscribeModal
        id={id}
        parentComponentId={parentComponentId}
        positionInParentComponent={positionInParentComponent}
        isModalOpened={isModalOpened}
        componentId={content.sys.id}
        onClose={() => setIsModalOpened(false)}
        onSubmit={() => {
          localStorage.setItem(localStorageKey, 'true')
          handleDownload()
        }}
        copy={{
          bold: t('want-to-download-free-resources'),
          regular: t('subscribe-to-our-email-newsletter'),
          noText: t('i-will-just-download'),
        }}
        emailTags={emailTags}
      />
    </>
  )
}
