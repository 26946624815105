'use client'

import TrackClick from 'app/components/TrackClick/TrackClick.client'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import useTranslation from 'app/i18n/client'
import { useEffect, useState } from 'react'
import { IComposeComponentProps } from '../../types'
import styles from './JumpLinks.module.scss'

function JumpLink({
  id,
  parentComponentId,
  positionInParentComponent,
}: Pick<
  IComposeComponentProps,
  'id' | 'parentComponentId' | 'positionInParentComponent'
>) {
  const { t } = useTranslation()
  const [anchors, setAnchors] = useState<
    {
      id: string | null
      text: string | null
    }[]
  >([])

  useEffect(() => {
    const headers = document.querySelectorAll('h2[id^="section-header-"]')

    const headersObj = Array.from(headers)
      .map((header) => ({
        id: header.getAttribute('id'),
        text: header.textContent,
      }))
      .filter((header) => !!header?.id)

    if (headersObj.length <= 1) return

    setAnchors(headersObj.slice(1))
  }, [])

  return (
    <div data-testid='jump-links'>
      <span className='d-sm-block f-sm-3 f-md-2 text-italic mb-sm-3 mb-md-4'>
        {`${t('jump-to-section')}:`}
      </span>
      <ul
        className={`${styles.listContainer} list-reset m-sm-0 d-sm-flex flex-sm-wrap`}
      >
        {anchors.map((anchor, index) => (
          <li className='mr-sm-2' key={anchor.id}>
            <TrackClick
              className='f-sm-2 px-sm-4 Btn Btn--primary Btn--small no-underline'
              href={`#${anchor.id}`}
              isEngagementEvent={false}
              trackProps={{
                clickType: 'button',
                buttonName: 'section_anchor',
                buttonText: anchor.text ?? undefined,
                linkedContentUrl: anchor.id ?? undefined,
                unitName: 'jumplink',
                unitLocation: 'header',
                componentId: id,
                positionInUnit: index + 1,
                parentComponentId,
                positionInParentComponent,
              }}
            >
              <TrackImpression
                key={anchor.id}
                linkedContentUrl={anchor.id ?? undefined}
                unitName='jumplink'
                unitLocation='header'
                componentId={id}
                positionInUnit={index + 1}
                buttonName='section_anchor'
                buttonText={anchor.text ?? undefined}
                parentComponentId={parentComponentId}
                positionInParentComponent={positionInParentComponent}
              />
              {anchor.text}
            </TrackClick>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default JumpLink
