import { ISvgProps } from './Svg.interface'

export default function SvgPrinter({ className }: ISvgProps) {
  return (
    <svg
      className={className}
      data-testid='svg-printer'
      fill='currentColor'
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      focusable='false'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 10H7.56979e-06L0 36C0 38 2 40 4 40H10V50H40V40H46.5C49 40 50 38 50 36V10H40V0H10V10ZM15 10H35V5H15V10ZM10 28V35H5V15H45V35H40V28V23H35H15H10V28ZM35 28V40L35 44.999H15V40V28H35Z'
      />
    </svg>
  )
}
