'use client'

import { IComposeComponentProps } from 'app/components/Compose/types'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import useTranslation from 'app/i18n/client'
import React, { useState } from 'react'

interface IFakeLoadMoreProps
  extends Pick<
    IComposeComponentProps,
    'id' | 'parentComponentId' | 'positionInParentComponent'
  > {
  children: React.ReactNode
  className?: string
  limit?: number
}

const defaultLimit = 6

export default function FakeLoadMore({
  id,
  parentComponentId,
  positionInParentComponent,
  children,
  className,
  limit,
}: IFakeLoadMoreProps) {
  const { t } = useTranslation()
  const [show, setShow] = useState(limit || defaultLimit)

  return (
    <>
      <ul className={className}>
        {React.Children.toArray(children).slice(0, show)}
      </ul>
      {React.Children.count(children) > show && (
        <div className='mt-sm-5 text-center no-print'>
          <TrackClick
            as='button'
            type='button'
            onClick={() => setShow(show + (limit || defaultLimit))}
            className='Btn Btn--outline'
            data-testid='resource-list-explore-more'
            isEngagementEvent={false}
            trackProps={{
              clickType: 'button',
              buttonName: 'load_more',
              buttonText: t('explore-more'),
              unitName: id,
              unitLocation: 'inline',
              componentId: id,
              parentComponentId,
              positionInParentComponent,
            }}
          >
            <TrackImpression
              unitName={id}
              unitLocation='inline'
              componentId={id}
              buttonName='load_more'
              buttonText={t('explore-more')}
              parentComponentId={parentComponentId}
              positionInParentComponent={positionInParentComponent}
            />
            {t('explore-more')}
          </TrackClick>
        </div>
      )}
    </>
  )
}
