'use client'

import trackEngagement from 'app/components/Snowplow/trackEngagement'
import SvgEmail from 'app/components/Svg/SvgEmail'
import SvgLoading from 'app/components/Svg/SvgLoading'
import SvgPrinter from 'app/components/Svg/SvgPrinter'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import { useRef, useState } from 'react'

const UNIT_VARIATION = 'default_content'

async function getObjectUrlFromDownloadUrl(url: string) {
  const response = await fetch(url)
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

interface IDefaultContentProps {
  title: string
  contentId: string
  dek: string
  url: string
  id: string
  index: number
  parentComponentId: string
  positionInParentComponent: number
  isExternalLink?: boolean
}

interface IBtnProps {
  title: string
  url: string
  id: string
  contentId: string
  index: number
  parentComponentId: string
  positionInParentComponent: number
}

function EmailBtn({
  title,
  url,
  id,
  contentId,
  index,
  parentComponentId,
  positionInParentComponent,
}: IBtnProps) {
  return (
    <TrackClick
      as='a'
      href={`mailto:?subject=${encodeURIComponent(
        title || ''
      )}&body=${encodeURIComponent(url)}`}
      aria-label='email'
      data-testid='default-email-link'
      isEngagementEvent
      trackProps={{
        eventType: 'share',
        platform: 'email',
        unitName: id,
        componentId: contentId,
        unitLocation: 'inline',
        positionInUnit: index + 1,
        unitVariation: UNIT_VARIATION,
        parentComponentId,
        positionInParentComponent,
        linkedContentId: contentId,
        linkedContentUrl: url,
      }}
    >
      <SvgEmail className='w-sm-4 mr-sm-3' />
    </TrackClick>
  )
}

function PrintBtn({
  title,
  url,
  id,
  contentId,
  index,
  parentComponentId,
  positionInParentComponent,
}: IBtnProps) {
  const [loading, setLoading] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [urlBlob, setUrlBlob] = useState<string>()

  const handlePrint = async () => {
    if (loading) return

    trackEngagement({
      eventType: 'share',
      platform: 'print',
      unitName: id,
      componentId: id,
      unitLocation: 'inline',
      positionInUnit: index + 1,
      unitVariation: UNIT_VARIATION,
      parentComponentId,
      positionInParentComponent,
      linkedContentId: contentId,
      linkedContentUrl: url,
    })

    if (iframeRef.current) {
      iframeRef.current.contentWindow?.print()
      return
    }

    setLoading(true)
    const objectUrl = await getObjectUrlFromDownloadUrl(url)
    setUrlBlob(objectUrl)
  }

  return (
    <button
      type='button'
      disabled={loading}
      className='Btn-link'
      onClick={handlePrint}
      aria-label='print'
      data-testid='default-print-btn'
    >
      {loading ? (
        <SvgLoading className='w-sm-4 fill-foreground-darkblue' />
      ) : (
        <SvgPrinter className='w-sm-4' />
      )}

      {/* using the iframe was the only way I could trigger window.print()
       if any new option is available, please replace it */}
      {urlBlob && (
        <iframe
          className='d-sm-none'
          ref={iframeRef}
          title={title}
          src={urlBlob}
          data-testid='default-print-iframe'
          onLoad={() => {
            if (!iframeRef.current) return
            iframeRef.current.contentWindow?.print()
            setLoading(false)
          }}
        />
      )}
    </button>
  )
}

export default function DefaultContent({
  title,
  contentId,
  dek,
  url,
  id,
  index,
  parentComponentId,
  positionInParentComponent,
  isExternalLink,
}: IDefaultContentProps) {
  return (
    <div
      data-testid={`default-content-${index}`}
      className={`px-sm-4 py-sm-5 p-md-0 w-sm-full bg-background-low-white rounded-sm-1 border-shadow 
        d-sm-flex flex-md-column-reverse`}
    >
      <TrackImpression
        as='span'
        key={contentId}
        linkedContentId={contentId}
        linkedContentUrl={url}
        unitName={id}
        unitLocation='inline'
        componentId={id}
        positionInUnit={index + 1}
        unitVariation={UNIT_VARIATION}
        isContent
        parentComponentId={parentComponentId}
        positionInParentComponent={positionInParentComponent}
      />

      <div className='p-md-4 flex-sm d-sm-flex flex-sm-column flex-sm-justify-between mr-sm-4 mr-md-0'>
        <div className='lc-sm-3 p-sm-relative mb-sm-2'>
          <TrackClick
            href={url}
            className='primary-link no-underline lh-condensed f-sm-4 f-md-5 stretched-link'
            data-testid='default-content-link'
            isEngagementEvent={false}
            trackProps={{
              clickType: 'text',
              linkText: title,
              isContent: true,
              buttonName: null,
              linkedContentId: contentId,
              linkedContentUrl: url,
              unitName: id,
              componentId: contentId,
              unitLocation: 'inline',
              positionInUnit: index + 1,
              unitVariation: UNIT_VARIATION,
              parentComponentId,
              positionInParentComponent,
            }}
          >
            {title}
          </TrackClick>
        </div>

        <div className='mb-sm-4 mb-md-5 f-sm-3 flex-sm'>{dek}</div>

        <ul className='list-reset d-sm-flex'>
          {!isExternalLink && (
            <li className='mr-sm-5'>
              <PrintBtn
                title={title}
                url={url}
                id={id}
                contentId={contentId}
                index={index}
                parentComponentId={parentComponentId}
                positionInParentComponent={positionInParentComponent}
              />
            </li>
          )}

          <li>
            <EmailBtn
              title={title}
              url={url}
              id={id}
              contentId={contentId}
              index={index}
              parentComponentId={parentComponentId}
              positionInParentComponent={positionInParentComponent}
            />
          </li>
        </ul>
      </div>
    </div>
  )
}
