import { IComposeComponentProps } from 'app/components/Compose/types'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import SvgClose from 'app/components/Svg/SvgClose'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import useTranslation from 'app/i18n/client'
import ReactModal from 'react-modal'
import { useMedia } from 'the-platform/useMedia'
import EmailForm from './EmailForm.client'

const customStylesMd = {
  content: {
    padding: '52px',
    borderRadius: '4px',
    borderWidth: '0',
    backgroundColor: 'var(--background-low-creamLite)',
    width: 500,
    height: 430,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 100 },
}

const customStylesSm = {
  content: {
    left: 0,
    right: 0,
    padding: '52px 30px',
    borderRadius: '0',
    height: 415,
    borderWidth: '0',
    backgroundColor: 'var(--background-low-creamLite)',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  overlay: { zIndex: 100 },
}

export interface ISubscribeModalProps
  extends Pick<
    IComposeComponentProps,
    'id' | 'parentComponentId' | 'positionInParentComponent'
  > {
  componentId: string
  isModalOpened: boolean
  onClose: () => void
  onSubmit: () => void
  emailTags: string[]
  copy: {
    bold?: string
    regular?: string
    noText?: string
  }
}

function SubscribeModal({
  isModalOpened,
  onClose,
  onSubmit,
  id,
  emailTags,
  copy,
  componentId,
  parentComponentId,
  positionInParentComponent,
}: ISubscribeModalProps) {
  const isMd = useMedia({ minWidth: 768 })
  const { t } = useTranslation()

  const handleOnSubmit = (email: string) => {
    trackEngagement({
      eventType: 'subscribe',
      submittedText: email,
      submitSuccess: true,
      unitName: 'account_gate',
      unitLocation: 'modal',
      unitVariation: id,
      componentId,
      parentComponentId,
      positionInParentComponent,
    })
    onSubmit()
  }

  return (
    <ReactModal
      isOpen={isModalOpened}
      onRequestClose={onClose}
      style={isMd ? customStylesMd : customStylesSm}
      preventScroll
    >
      <button
        type='button'
        aria-label={t('close-email-subscription-window')}
        data-testid='close-modal'
        onClick={() => {
          trackEngagement({
            eventType: 'dismiss',
            unitName: 'account_gate',
            unitLocation: 'modal',
            componentId,
            unitVariation: id,
            parentComponentId,
            positionInParentComponent,
          })
          onClose()
        }}
        className='Btn-link p-sm-absolute right-sm-4 top-sm-4 right-md-5 top-md-5'
      >
        <SvgClose className='w-sm-4' />
      </button>
      <div>
        <TrackImpression
          unitName='account_gate'
          buttonName={null}
          unitLocation='modal'
          unitVariation={id}
          componentId={componentId}
          parentComponentId={parentComponentId}
          positionInParentComponent={positionInParentComponent}
        />

        <h1 className='f-sm-5 mb-sm-3 lh-condensed text-regular'>
          {copy.bold && <strong>{copy.bold} </strong>}
          {copy.regular ?? ''}
        </h1>

        <EmailForm
          onSubmit={(email) => {
            handleOnSubmit(email)
            onClose()
          }}
          emailTags={emailTags}
        />

        {copy.noText && (
          <button
            type='button'
            className='mt-sm-4 text-foreground-midblue Btn-link text-underline f-sm-4'
            onClick={() => {
              onSubmit()
              onClose()
            }}
          >
            {copy.noText}
          </button>
        )}
      </div>
    </ReactModal>
  )
}

export default SubscribeModal
