'use client'

import useGlobalContext from 'app/components/GlobalContext/useGlobalContext'
import Link from 'app/components/Link/Link.client'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import SvgEmail from 'app/components/Svg/SvgEmail'
import SvgLoading from 'app/components/Svg/SvgLoading'
import useTranslation from 'app/i18n/client'
import React, { ChangeEvent, FormEvent, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'
import { IComposeComponentProps } from '../types'
import styles from './ComponentNewsletter.module.scss'

type FormState = 'IDLE' | 'CHANGED' | 'SAVING' | 'SUCCESS' | 'ERROR'

interface IComponentNewsletterProps
  extends Pick<
    IComposeComponentProps,
    'id' | 'positionInParentComponent' | 'parentComponentId'
  > {
  tags: string[]
  title?: string | null
  description: React.ReactNode
  checkboxes: {
    tag?: string | null
    label?: string | null
  }[]
}

export default function ComponentNewsletter({
  id,
  tags,
  title,
  description,
  checkboxes,
  parentComponentId,
  positionInParentComponent,
}: IComponentNewsletterProps) {
  const { t } = useTranslation()
  const { locale, slug } = useGlobalContext()
  const uniqueId = useRef(uuidV4())
  const [email, setEmail] = useState('')
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [formState, setFormState] = useState<FormState>('IDLE')
  const [errorMessage, setErrorMessage] = useState('')

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    setFormState('CHANGED')
    setErrorMessage('')
  }

  const submitForm = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    const isValidEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)

    trackEngagement({
      eventType: 'subscribe',
      submittedText: email,
      submitSuccess: !!isValidEmail,
      unitName: id,
      unitLocation: 'footer',
      componentId: id,
      parentComponentId,
      positionInParentComponent,
    })

    if (!isValidEmail) {
      setErrorMessage(t('invalid-email'))
      return
    }

    setFormState('SAVING')

    const isEducators = slug.indexOf('/educators') > -1
    const selectedRoles = Array.from(
      new Set(tags.concat(isEducators ? ['educators'] : []))
    )

    try {
      await fetch(
        `${process.env.NEXT_PUBLIC_API_DOMAIN}/subscribers/${locale}/subscribe`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            // sending cannes as `web_subscription_list` is a one-time thing requested by the BE
            // https://understood.atlassian.net/wiki/spaces/BE/pages/2792718341/Cannes+Campaign
            tag: [...tags, ...selectedTags].filter(
              (tag) => !tag.startsWith('cannes-')
            ),
            web_subscription_list: selectedTags.filter((tag) =>
              tag.startsWith('cannes-')
            ),
            web_user_selected_roles: selectedRoles,
            join_source_url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}${slug}`,
          }),
        }
      )
      setEmail('')
      setFormState('SUCCESS')
    } catch {
      setErrorMessage(t('something-went-wrong'))
      setFormState('ERROR')
    }
  }

  return (
    <>
      {formState !== 'SUCCESS' && (
        <>
          <h2 className='f-sm-6 f-md-7 mb-sm-3 lh-condensed'>{title}</h2>
          <div className='f-sm-3 f-md-5 mb-sm-4'>{description}</div>

          <form onSubmit={submitForm} noValidate>
            <div className='mb-sm-4'>
              <label
                htmlFor={`newsletter_email_input_${uniqueId.current}`}
                className='p-sm-relative text-foreground-darkblue mb-sm-3 text-bold f-sm-2 d-sm-inline-block'
              >
                {t('email-address')}
                <span aria-label='required' className='text-static-scarletTint'>
                  *
                </span>
              </label>
              <input
                id={`newsletter_email_input_${uniqueId.current}`}
                className='form-control  form-control--noborder'
                value={email}
                required
                aria-required='true'
                aria-invalid={!errorMessage ? undefined : 'true'}
                onChange={handleEmailChange}
                onFocus={() => setFormState('CHANGED')}
                data-testid='newsletter-signup-input'
                disabled={formState === 'SAVING'}
              />
              <div
                id={`newsletter_email_input_${uniqueId.current}_error`}
                data-testid='newsletter-error-message'
                role='alert'
                className='f-sm-2 mt-sm-3 mb-sm-4'
              >
                {errorMessage}
              </div>
            </div>

            {formState !== 'IDLE' &&
              checkboxes.map((newsletter, index) => {
                const { tag, label } = newsletter
                if (!tag || !label) return null

                return (
                  <div
                    className={`mb-sm-4 ${styles.fadeIn}`}
                    style={{ animationDelay: `${index * 0.03}s` }}
                  >
                    <label
                      className='form-choice-label d-sm-flex mb-sm-1'
                      htmlFor={tag}
                    >
                      <input
                        className='form-choice form-checkbox m-sm-2'
                        type='checkbox'
                        disabled={formState === 'SAVING'}
                        id={tag}
                        name={label}
                        onChange={() => {
                          setSelectedTags((currentValue) =>
                            currentValue.includes(tag)
                              ? currentValue.filter((item) => item !== tag)
                              : selectedTags.concat(tag)
                          )
                        }}
                      />
                      <span className='form-choice-span m-sm-0' />
                      <div className='ml-sm-1 text-bold'>{label}</div>
                    </label>
                  </div>
                )
              })}

            <div className='d-sm-flex flex-sm-align-center mb-sm-5'>
              <button
                type='submit'
                disabled={formState === 'SAVING'}
                className='Btn Btn--primary no-underline'
                aria-describedby={`emailTerms_${uniqueId.current}`}
                data-testid='newsletter-signup-button'
              >
                <SvgEmail className='mr-sm-2 w-sm-4' />
                {t('subscribe')}
              </button>
              {formState === 'SAVING' && (
                <SvgLoading className='ml-sm-4 w-sm-4' />
              )}
            </div>

            <div id={`emailTerms_${uniqueId.current}`} className='f-sm-2'>
              <Trans t={t} i18nKey='newsletter-disclaimer'>
                Review our
                <Link href='/privacy-policy'>privacy policy</Link>. You can opt
                out of emails at any time by sending a request to
                <a className='primary-link' href='mailto:info@understood.org'>
                  info@understood.org
                </a>
                .
              </Trans>
            </div>
          </form>
        </>
      )}
      {formState === 'SUCCESS' && (
        <div
          className={`text-foreground-midblue f-sm-6 d-sm-flex flex-sm-align-center
            flex-sm-justify-center px-sm-6 py-sm-7 text-center`}
          role='status'
          data-testid='newsletter-success-banner'
        >
          {t('newsletter-success')}
        </div>
      )}
    </>
  )
}
