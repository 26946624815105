'use client'

import { ArticleFragment } from '@generated/schema'
import { IComposeComponentProps } from 'app/components/Compose/types'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import SvgEmail from 'app/components/Svg/SvgEmail'
import SvgLoading from 'app/components/Svg/SvgLoading'
import SvgPrinter from 'app/components/Svg/SvgPrinter'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import { getContentUrl } from 'components/shared/utils/getContentUrl'
import Image from 'next/image'
import { useRef, useState } from 'react'
import styles from './ArticleResourceContent.module.scss'

interface IArticleResourceContentProps
  extends Pick<
    IComposeComponentProps,
    'id' | 'parentComponentId' | 'positionInParentComponent'
  > {
  index: number
  article: ArticleFragment
}

const FALLBACK_IMG =
  'https://images.ctfassets.net/p0qf7j048i0q/hJEgpmjWW4RhB03AdHmpq/8e08ba4df94c7f59bb2fa91104ca9815/empty_article.svg'
const UNIT_VARIATION = 'article_content'

function PrintBtn({
  positionInParentComponent,
  parentComponentId,
  id,
  index,
  article,
}: IArticleResourceContentProps) {
  const [loading, setLoading] = useState(false)
  const [loadIframe, setLoadIframe] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const url = getContentUrl(article)

  const handlePrint = () => {
    if (loading) return

    trackEngagement({
      eventType: 'share',
      platform: 'print',
      unitName: id,
      componentId: article.sys.id,
      unitLocation: 'inline',
      positionInUnit: index + 1,
      unitVariation: UNIT_VARIATION,
      parentComponentId,
      positionInParentComponent,
      linkedContentId: article.sys.id,
      linkedContentUrl: url,
    })

    if (iframeRef.current) {
      iframeRef.current.contentWindow?.print()
    }

    if (!iframeRef.current) {
      setLoadIframe(true)
      setLoading(true)
    }
  }

  return (
    <button
      disabled={loading}
      className='Btn-link'
      onClick={handlePrint}
      aria-label='print'
      type='button'
      data-testid='resource-center-print-btn'
    >
      {loading ? (
        <SvgLoading className='w-sm-4 fill-foreground-darkblue' />
      ) : (
        <SvgPrinter className='w-sm-4' />
      )}

      {/* using the iframe was the only way I could trigger window.print()
       if any new option is available, please replace it */}
      {loadIframe && (
        <iframe
          className='d-sm-none'
          ref={iframeRef}
          title='print'
          src={`${process.env.NEXT_PUBLIC_APP_DOMAIN + url}?gate=none`}
          data-testid='resource-center-print-iframe'
          onLoad={() => {
            if (!iframeRef.current) return

            iframeRef.current.contentWindow?.print()
            setLoading(false)
          }}
        />
      )}
    </button>
  )
}

function EmailBtn({
  positionInParentComponent,
  parentComponentId,
  id,
  index,
  article,
}: IArticleResourceContentProps) {
  const url = getContentUrl(article)

  return (
    <TrackClick
      as='a'
      href={`mailto:?subject=${encodeURIComponent(
        article.title || ''
      )}&body=${encodeURIComponent(
        `${process.env.NEXT_PUBLIC_APP_DOMAIN + url}`
      )}`}
      aria-label='email'
      data-testid='resource-center-email-link'
      isEngagementEvent
      trackProps={{
        eventType: 'share',
        platform: 'email',
        unitName: id,
        unitLocation: 'inline',
        positionInUnit: index + 1,
        unitVariation: UNIT_VARIATION,
        componentId: article.sys.id,
        parentComponentId,
        positionInParentComponent,
        linkedContentId: article.sys.id,
        linkedContentUrl: url,
      }}
    >
      <SvgEmail className='w-sm-4 mr-sm-3' />
    </TrackClick>
  )
}

export default function ArticleResourceContent({
  id,
  parentComponentId,
  positionInParentComponent,
  article,
  index,
}: IArticleResourceContentProps) {
  const articleUrl = getContentUrl(article)

  return (
    <div
      data-testid={`article-resource-content-${index}`}
      className={`px-sm-4 py-sm-5 p-md-0 w-sm-full bg-background-low-white rounded-sm-1
        border-shadow d-sm-flex flex-md-column-reverse`}
    >
      <TrackImpression
        as='span'
        key={article.sys.id}
        linkedContentId={article.sys.id}
        linkedContentUrl={articleUrl}
        unitName={id}
        unitLocation='inline'
        componentId={article.sys.id}
        positionInUnit={index}
        unitVariation={UNIT_VARIATION}
        isContent
        linkText={article.title}
        parentComponentId={parentComponentId}
        positionInParentComponent={positionInParentComponent}
      />

      <div className='p-md-4 flex-sm d-sm-flex flex-sm-column flex-sm-justify-between mr-sm-4 mr-md-0'>
        <div className='lc-sm-3 p-sm-relative mb-sm-4 mb-md-5'>
          <TrackClick
            href={`${articleUrl}?from=app`}
            className='primary-link no-underline lh-condensed f-sm-4 f-md-5 stretched-link'
            data-testid='article-resource-content-link'
            isEngagementEvent={false}
            trackProps={{
              clickType: 'text',
              isContent: true,
              linkText: article.title,
              linkedContentId: article.sys.id,
              linkedContentUrl: articleUrl,
              unitName: id,
              componentId: article.sys.id,
              unitLocation: 'inline',
              positionInUnit: index + 1,
              unitVariation: UNIT_VARIATION,
              parentComponentId,
              positionInParentComponent,
            }}
          >
            {article.title}
          </TrackClick>
        </div>

        <ul className='list-reset d-sm-flex'>
          <li className='mr-sm-5'>
            <PrintBtn
              id={id}
              index={index}
              parentComponentId={parentComponentId}
              positionInParentComponent={positionInParentComponent}
              article={article}
            />
          </li>

          <li>
            <EmailBtn
              id={id}
              index={index}
              parentComponentId={parentComponentId}
              positionInParentComponent={positionInParentComponent}
              article={article}
            />
          </li>
        </ul>
      </div>

      <div
        className={`${styles.imageWrapper} p-sm-relative rounded-sm-1 o-sm-hidden no-print`}
      >
        <Image
          sizes='(max-width: 767px) 120px, 300px'
          className='of-sm-cover'
          fill
          src={
            article.thumbnail?.url ||
            article.heroImage?.imageFile?.url ||
            FALLBACK_IMG
          }
          alt=''
        />
      </div>
    </div>
  )
}
