import useGlobalContext from 'app/components/GlobalContext/useGlobalContext'
import SvgEmail from 'app/components/Svg/SvgEmail'
import useTranslation from 'app/i18n/client'
import { usePathname } from 'next/navigation'
import { useRef, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

interface IEmailFormProps {
  emailTags: string[]
  onSubmit: (email: string) => void
}

export default function EmailForm({ onSubmit, emailTags }: IEmailFormProps) {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [error, toggleEmailError] = useState(false)
  const { locale } = useGlobalContext()
  const pathName = usePathname()
  const uniqueId = useRef(uuidV4())

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    toggleEmailError(false)
  }

  const submitForm = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    const isValidEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    if (!isValidEmail) {
      toggleEmailError(true)
      return
    }

    await fetch(
      `${process.env.NEXT_PUBLIC_API_DOMAIN}/subscribers/${locale}/subscribe`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          tag: emailTags,
          web_user_selected_roles: emailTags,
          join_source_url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}${pathName}`,
        }),
      }
    )
    onSubmit?.(email)
  }

  return (
    <div className='grid-col-sm-12'>
      <div className='grid grid-gap-none-sm '>
        <div className='grid-col-sm-12'>
          <form onSubmit={submitForm}>
            <div className='mb-sm-4'>
              <label
                htmlFor={`email_form_${uniqueId.current}`}
                className='p-sm-relative text-foreground-darkblue mb-sm-3 text-bold f-sm-2 d-sm-inline-block'
              >
                {t('email-address')}
                <span aria-label='required' className='text-static-scarletTint'>
                  *
                </span>
              </label>
              <input
                id={`email_form_${uniqueId.current}`}
                className='form-control'
                value={email}
                required
                aria-required='true'
                aria-invalid={!error ? undefined : 'true'}
                onChange={handleEmailChange}
                data-testid='newsletter-signup-input'
              />

              {error && (
                <div
                  id={`email_form_${uniqueId.current}_error`}
                  data-testid='newsletter-error-message'
                  role='alert'
                  className='f-sm-2 mt-sm-3 mb-sm-4'
                >
                  {t('enter-a-valid-email')}
                </div>
              )}
            </div>

            <div>
              <button type='submit' className='Btn Btn--shadow Btn--responsive'>
                <SvgEmail className='w-sm-4 mr-sm-2' />
                {t('subscribe')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
