'use client'

import { useSearchParams } from 'next/navigation'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import { IComposeComponentProps } from '../types'

interface IComponentFilteredResources
  extends Pick<
    IComposeComponentProps,
    'id' | 'parentComponentId' | 'positionInParentComponent'
  > {
  resources: {
    filter: string
    content: {
      sys: { id: string }
      component: React.ReactNode
    }[]
  }[]
}

function ComponentFilteredResources({
  id,
  resources,
  parentComponentId,
  positionInParentComponent,
}: IComponentFilteredResources) {
  const searchParams = useSearchParams()

  const contentFilter = searchParams?.get('filter') || 'all_ages'
  const collection =
    resources.find((c) => c.filter === contentFilter)?.content || []

  return (
    <>
      <TrackImpression
        unitName={id}
        componentId={id}
        unitLocation='inline'
        childComponentId={collection.map((c) => c.sys.id)}
        parentComponentId={parentComponentId}
        positionInParentComponent={positionInParentComponent}
      />
      {collection?.map((c) => c.component)}
    </>
  )
}

export default ComponentFilteredResources
