'use client'

import useDarkMode from 'use-dark-mode'
import { IPodcastProps } from './Podcast.interface'

function Podcast({ type, audioId, ...props }: IPodcastProps) {
  const { value: isDarkMode } = useDarkMode()
  const src = `https://player.simplecast.com/${audioId}?dark=${isDarkMode.toString()}`

  return (
    <div data-testid='podcast-player' {...props}>
      <iframe
        title='Podcast Player'
        key={src}
        loading='lazy'
        height={type === 'standard' ? '200px' : '52px'}
        width='100%'
        frameBorder='no'
        scrolling='no'
        seamless
        src={src}
      />
    </div>
  )
}

export default Podcast
